.location-details-form {
    padding-top: 4px;
    & .position {
        & .position-label {
            padding: 0px !important;
            margin-top: 8px;
            color: var(--text-light-lightgrey) !important;
        }
        & input {
            margin-top: 4px;
        }
        & .leaflet-container {
            min-height: 300px !important;
            cursor: crosshair;
            & .leaflet-popup-tip-container {
                display: none !important;
            }
        }
        & .leaflet-popup-close-button {
            padding-right: 24px !important;
            padding-top: 12px !important;
        }
        & .leaflet-control-zoom.leaflet-control {
            & .leaflet-control-zoom-in {
                cursor: zoom-in;
                width: 15px !important;
                height: 15px !important;
                font-size: 0 !important;
            }
            & .leaflet-control-zoom-out {
                cursor: zoom-out;
                width: 15px !important;
                height: 15px !important;
                font-size: 0 !important;
            }
        }
        & .coordinates {
            & input {
                width: 100%;
            }
        }
        & input:disabled {
            border-color: var(--layer-gray);
            color: var(--text-light-darkgrey);
            opacity: 1;
        }

        & div.text-md {
            display: inline-block;
        }
        & .tooltip {
            padding-left: 4px !important;
        }
    }

    & .type {
        & div.select {
            min-width: 100% !important;
            padding: 0 !important;
        }
        & .options-list.overflow {
            width: 100% !important;
            left: 0 !important;
        }
        & button.btn {
            width: 100% !important;
            left: 0 !important;
        }
    }

    & .type .select.open {
        height: calc(32px * 5);
    }

    & .resource-types {
        & .resource-label {
            padding-left: 0px !important;
            color: var(--text-light-lightgrey) !important;
        }
    }

    & .picture {
        & .picture-gallery {
            padding: 0;
        }
        & button {
            width: 100%;
        }
    }

    & .actions {
        display: flex;
        justify-content: right;
        button {
            max-width: 30%;
            margin-top: 8px;
        }
    }

    & .warning {
        display: flex;
        justify-content: right;
        color: var(--status-red);
        padding-right: 8px;
    }

    & textarea {
        min-height: 100px;
    }

    & div.text-md {
        padding: 0px !important;
        padding-bottom: 4px !important;
    }
    & span.text-sm.subtxt {
        padding: 0px !important;
        padding-bottom: 8px !important;
        padding-right: 8px !important;
        color: var(--text-light-lightgrey) !important;
    }
    & div {
        padding: 8px;
    }
    & input {
        width: 100% !important;
    }
    & textarea {
        width: 100% !important;
    }
}

.error-msg {
    padding: 8px;
}
