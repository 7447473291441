.contacts-table-page .count-selected {
    display: none;
}

.tile.contacts-table-tile  {
    padding: 0%;
}

.contacts-table-page .table-bottom .pagination-group .actions label:nth-child(3) {
    margin: 0%;
    position: relative;
    left: 10px;
}

@media screen and (max-width: 420px) {
    .table.contacts-table {
        font-size: small;
    }
    .table.contacts-table thead tr th {
        padding: 8px 2px;
    }
    .table.contacts-table tbody tr td {
        padding: 8px 2px;
        white-space: break-spaces;
        word-break: break-word;
    }
    .contacts-table-page .table-top {
        font-size: small;
    }
}

@media screen and (max-width:360px){
    .table.contacts-table {
        font-size: x-small;
    }
}

@media screen and (max-width:280px){
    .table.contacts-table {
        font-size: xx-small;
    }
    .table.contacts-table tbody tr td {
        font-size: 8px;
        word-break: break-word;
    }
    .contacts-table-page .table-bottom .pagination-group {
        font-size: x-small;
    }
    .contacts-table-page .table-bottom .pagination-group .actions label {
        font-size: x-small;
    }
    .contacts-table-page .table-bottom .pagination-group .actions span input[type="number"] {
        font-size: x-small;
        width: 36px;
        margin-right: 10px;
    }
    .contacts-table-page .table-bottom .pagination-group .actions .select .btn {
        font-size: x-small;
        min-width: 36px;
        padding: 6px;
    }
    .contacts-table-page .table-bottom .pagination-group .actions .select .options-list {
        width: 50px !important;
    }
    .contacts-table-page .table-bottom .pagination-group .actions .options-list .item {
        font-size: x-small;
    }
}
