.leaflet-container {
    width: 100%;
    height: 100%;
}

.location-details .map-main-container.leaflet-container
{
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  padding: 4px;
}

.leaflet-popup-content {
  margin: 0px;
}

.leaflet-tooltip {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: bold;
}

.leaflet-tooltip-bottom:before {
  border-style: none !important;
  background: transparent !important;
}

.leaflet-popup-content .card {
  padding: 8px !important;
  border: none !important;
  & .location-name {
    padding: 4px;
  }
  & .text-md {
    font-weight: bold !important;
  }
}
img.logo-image-locations{
  margin: 0% !important;
  padding: 0% !important;
  border-radius: 100%;
  width: 24px;
  height: 24px;
}
.cluster-logo.pill{
  margin: 0% !important;
  padding: 0% !important;
  border-width: 1.4px !important;
}
.icon.cluster {
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: 8px !important;
  height: 8px !important;
}
.cluster.pill {
  color: var(--text-black);
  background-color: var(--layer-light-three);
  border-width: 1.4px !important;
  font-family: "Ericsson Hilda", Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.measure-point-number {
  background-color: inherit !important;
}

.leaflet-popup-content .content .status-icon {
  &.indeterminate {
    color: var(--status-black);
  }
  &.hypertrophic {
    color: var(--status-light-blue);
  }
  &.eutrophic {
    color: var(--status-green);
  }
  &.mesotrophic {
    color: var(--status-yellow);
  }
  &.oligotrophic {
    color: var(--status-orange);
  }
  &.ultra_oligotrophic {
    color: var(--status-red);
  }
}

.dark {
  & .leaflet-popup-content-wrapper .content{
    color: var(--text-dark-lightgrey);
  }
  & .leaflet-popup-content-wrapper .title{
    color: var(--text-dark-lightgrey);;
  }
  & .leaflet-popup-content-wrapper {
    background: var(--layer-dark-two);
  }
  & .leaflet-popup-tip {
    background: var(--layer-dark-two);
  }
  & .leaflet-popup-content .content .status-text {
    color: var(--text-dark-darkgrey);
  }
  & .leaflet-popup-content .content .status-icon {
    &.indeterminate {
      color: var(--status-white);
    }
    &.hypertrophic {
      color: var(--status-dark-blue);
    }
  }
}
.light {
  & .leaflet-popup-content-wrapper .content{
    color: var(--text-light-darkgrey);
    padding-top: 0% !important;
  }
  & .leaflet-popup-content-wrapper .title{
    color: var(--text-light-darkgrey);
    margin-right: 5px;
  }
  & .leaflet-popup-content-wrapper {
    background: var(--layer-white);
  }
  & .leaflet-popup-tip {
    background: var(--layer-white);
  }
  & .leaflet-popup-content .content .status-text {
    color: var(--text-dark-darkgrey);
  }
}

.leaflet-popup-close-button {
  color: var(--layer-dark-two) !important;
  &:focus { 
    display: none;
  }
}

.card-content {
  margin-top: 16px;
}
.card-status-indexes {
  margin-top: 16px;
}