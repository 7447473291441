.measure-points {
    cursor: pointer !important;
    width: 100%;
}
.table.measure-points-table tr th {
    padding: 16px 8px;
}
.table.measure-points-table tr td {
    padding: 16px 8px;
}
.table.measure-points-table td.freeze {
    white-space: break-spaces !important;
} 
@media only screen and (max-width: 540px) {
    .measure-points-table {
        font-size: smaller;
    }
    .table.measure-points-table tr th {
        padding: 8px 4px;
        text-align: center;
    }
    .table.measure-points-table tr td {
        padding: 8px 4px;
    }
}
@media only screen and (max-width: 380px) {
    .measure-points-table {
        font-size: x-small;
    }
    .light .breadcrumb .link:last-child {
        font-size: 14px;
    }
}
