.entry-block {
    padding: 8px !important;
    & .content {
        padding: 0px;
    }
    & .file-input {
        width: 100%;
    }
    & .notes {
        width: 100%;
        min-height: 32px;
        height: 20vh;
    }
    & .entry-field {
        width: 75% !important;
    }
    & .suffix {
        width: 25% !important;
        cursor: default !important;
    }
    & .warning-message {
        color: var(--text-light-lightgrey);
    }
    & .error-message {
        color: var(--status-red) !important;
    }

    &.actions {
        display: flex;
        justify-content: right;
        padding: 16px;
    }
}

.loading.large {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50%;
}

.entry-form-error {
    margin: 10px;
}