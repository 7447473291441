.tooltip.pointer.contacts-button-locations .btn{
    height: min-content;
    font-size: 1rem;
}

.tooltip.pointer.contacts-button-locations .message.left {
    right: -3rem;
}

.locations-details-view .actions-right {
    position: relative;
    margin: 5px;
    right: 10%;
}

.data-attribution {
    color: var(--text-light-lightgrey);
}

 @media screen and (max-width: 1248px) {
    .locations-details-view .actions-right {
        right: 12%;
     }
 }
 @media screen and (max-width: 820px) {
    .locations-details-view .actions-right {
        right: 20%;
     }
 }

 @media screen and (max-width: 540px) {
    .locations-details-view .actions-right {
        right: 25%;
    }
 }
 @media screen and (max-width: 420px) {
    .locations-details-view .actions-right {
        right: 40%;
    }
    .locations-details-view .actions-left .title .breadcrumb {
        margin-top: 12px;
        display: flex;
        align-items: center;
     }
    .locations-details-view .actions-left .title .breadcrumb .link:last-child {
        width: 50%;
        white-space: break-spaces;
        font-size: 12px;
    }
 }
 @media screen and (max-width: 280px) {
    .tooltip.pointer.contacts-button-locations .btn{
        font-size: 0.5rem;
        margin-top: 0.5rem;
    }
    .locations-details-view .actions-left .title .breadcrumb {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }
    .locations-details-view .actions-left .title .breadcrumb .more {
        margin-right: 0%;
    }
    .locations-details-view .actions-left .title .breadcrumb .separator {
        margin-right: 0%;
    }
    .locations-details-view .actions-left .title .breadcrumb .link:last-child {
        width: 50%;
        white-space: break-spaces;
        font-size: 12px;
    }

 }