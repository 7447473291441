.history-form {
    padding: 8px !important;
    margin: 8px !important;
    & .skip-checkbox {
        display: inline !important;
    }
    & .history-upload-button {
        display: flex;
        justify-content: right;
    }
}
.progress-bar-value {
    display: none !important;
}
.progress-bar-progress {
    width: 100% !important;
}