.awssld {
    height: 75vh !important;
}

@media screen and (min-height: 600px) and (max-height: 700px) {
    .awssld {
        height: 77vh !important;
    }
}

@media screen and (min-height: 700px) and (max-height: 800px) {
    .awssld {
        height: 80vh !important;
    }
}

@media screen and (min-height: 800px) and (max-height: 1000px) {
    .awssld {
        height: 83vh !important;
    }
}

@media screen and (min-height: 1000px) and (max-height: 1281px) {
    .awssld {
        height: 86vh !important;
    }
}

.app-main-content {
    height: 100vh !important;
}
.awssld__controls__arrow-right {
    height: 20% !important;
    &:after {
        background-color: var(--layer-light-three) !important;
    }
    &:before {
        background-color: var(--layer-light-three) !important;
    }
}

.awssld__controls__arrow-left {
    height: 20% !important;
    &:after {
        background-color: var(--layer-light-three) !important;
    }
    &:before {
        background-color: var(--layer-light-three) !important;
    }
}

.awssld__bullets button {
    width: 12px !important;
    height: 12px !important;
    border: 1px solid #878787 !important;
    background-color: var(--layer-light-three) !important;
}

.awssld__bullets .awssld__bullets--active {
    background-color: #878787 !important;
    width: 12px !important;
    height: 12px !important;
    transform: none !important;
}

.image-background {
    z-index: 999;
    color: var(--layer-light-three);
    padding-left: 10% !important;
    padding-right: 10% !important;
    font-size: calc(0.9em + 1vmin);
}

.autoplay-slider-title {
    padding-bottom: 2%;
    z-index: 999;
    font-size: calc(1.5em + 1vmin);
    font-weight: bold;
    span {
        width: 100%;
    }
}

.autoplay-slider-logo-container {
    width: 100%;
}

.autoplay-slider-title-logo {
    display: block;
    z-index: 999;
    width: 15%;
}

.awssld__bullets {
    height: 3vh;
}
