.content-block {
    & .carousel-container {
        margin-top: 4px !important;
    }
    & .carousel-slide img {
        width: 100%;
        height: 100%;
    }
    & .values {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-top: 1px solid var(--text-dark-darkgrey);
        & .value-block {
            display: inline-block;
            width: 50%;
            padding: 8px;
            & .content {
                height: fit-content;
                overflow: hidden;
                padding: 0px;
            }
            & .resource-status {
                color: var(--text-light-lightgrey);
            }
        }
    }
}