.cookie-footer {
    position: absolute !important;
    height: 80px;
    bottom: 0px;
    width: 100% !important;
    background-color: var(--layer-dark-three);
    display: flex;
    flex-direction: row;
    z-index: 9999;
    padding: 12px 8px 12px 8px;
    &.hidden {
        visibility: hidden;
    }
}

.cookie-footer-close {
    color: var(--text-white);
    cursor: pointer;
}

.cookie-footer-message-container {
    width: 100%;
    padding: 4px 16px 4px 16px;
}

.cookie-footer-message {
    width: 100%;
    color: var(--text-white);
    font-size: 16px;
    display: flex;
    margin-right: 1.5rem;
}

.cookie-footer-href {
    font-size: 16px;
}

.cookie-footer-button-container {
    position: relative;
    padding: 8px 16px 8px 16px;
    width: 30%;
}

.cookie-footer-button {
    top: 8px;
    position: absolute;
    right: 16px;
    width: 145px !important;
}

@media all and (min-width:280px) and (max-width: 333px) {
    .cookie-footer {
        min-height: 130px;
        flex-direction: column;
    }

    .cookie-footer-message {
        font-size: 12px;
    }

    .cookie-footer-href{
        font-size: 12px;
    }

    .cookie-footer-button-container {
        padding: 8px 0px 0px 0px;
        left: 38%;
    }

    .cookie-footer-button {
        top: 0px;
        right: 0px;
        bottom: 12px;
        width: 90px !important;
        font-size: 12px !important;
        position: relative;
    }

    .cookie-footer-close {
        right: 8px;
        position: absolute;
        cursor: pointer;
    }
}

@media all and (min-width:333px) and (max-width: 592px) {
    .cookie-footer {
        min-height: 110px;
        flex-direction: column;
    }

    .cookie-footer-message {
        font-size: 12px;
    }

    .cookie-footer-href{
        font-size: 12px;
    }

    .cookie-footer-button-container {
        padding: 4px 0px 0px 0px;
        left: 38%;
    }

    .cookie-footer-button {
        top: 0px;
        right: 0px;
        bottom: 12px;
        width: 100px !important;
        font-size: 12px !important;
        position: relative;
    }

    .cookie-footer-close {
        right: 8px;
        position: absolute;
        cursor: pointer;
    }
}
