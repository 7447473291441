.location-details .measurements .tile .content {
    height: fit-content;
    min-height: 420px;
    & canvas {
        cursor: pointer;
    }
}

.tile.sampling-logs-quality-distribution-pie-tile {
    margin-bottom: 0 !important;
}

.location-details {
    & .btn::after {
        top: 42% !important;
        right: 5% !important
    }

}

.action .tooltip {
    padding-left: 10px !important;
}