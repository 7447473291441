.thresholds-overview-table {
    width: 100%;
}

.thresholds-error-message {
    color: red;
    text-align: center;
}

.number-field-min-max{
    display: flex;
    justify-content: center;
}
.number-field-min-max input:invalid {
    border: 2px solid red;
}
.number-field-min-max input[type="number"] {
    width: 72px;
    margin-right: 4px;
    padding: 4px
}

.number-field-min-max input[type="number"]:read-only{
    text-align: end;
}

.table.thresholds-overview-table tr td {
    padding: 8px;
    white-space: break-spaces;
}

.table.thresholds-overview-table tr th {
    text-align: center;
}

.button-group-bottom {
    display: flex;
    justify-content: flex-end;
}
.button-group-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
}

.button-add-new {
    margin: 10px 0;
    width: 100%;
}

.thresholds-select .current-options {
    left: 0% !important;
}

.thresholds-select .options-list {
    left: 0% !important;
}

.no-thresholds {
    color: var(--text-light-lightgrey);
}

.accordion.accordion-tsi .accordion-list .accordion-item .btn {
    margin-bottom: 16px;
}

@media screen and (max-width: 420px) {
    .thresholds-overview-table {
        font-size: x-small;
        padding: 4px;
    }
    .thresholds-overview-table .btn {
        font-size: x-small;
        padding: 4px;
        min-width: 48px;
    }
    .table.thresholds-overview-table td.freeze {
        white-space: break-spaces !important;
    }
    .number-field-min-max input[type="number"]{
        width: 2.5rem;
        margin-right: 4px;
        padding: 1px;
        font-size: x-small;
    }
    .table.thresholds-overview-table tr td {
        padding: 4px;
    }
    .table.thresholds-overview-table tr th {
        padding: 8px;
    }

    .thresholds-select label {
        font-size: x-small;
    }
    .thresholds-select .current-options {
        width: 130px !important;
    }
    .thresholds-select .options-list {
        width: 130px !important;
    }
}

@media screen and (max-width: 360px) {
    .thresholds-overview-table {
        font-size: x-small;
        padding: 2px;
    }
    .number-field-min-max input[type="number"] {
        margin-right: 2px;
        padding: 2px;
        font-size: xx-small;
    }
    .accordion.accordion-tsi .accordion-list .accordion-item .tile{
        padding: 4px;
    }
    .accordion.accordion-tsi .accordion-list .accordion-item .btn {
        font-size: xx-small;
        padding: 4px 8px;
        min-width: 40px;
    }
}