@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
:root {
    --status-white: #F2F2F2;
    --status-black: #181818;
    --status-green: #288964;
    --status-yellow: #DCAF00;
    --status-orange: #E66E19;
    --status-red: #DC2D37;
    --status-grey: #ADADAD;
    --status-light-blue: #1174E6;
    --status-dark-blue: #0077DB;
    
    --layer-light-one: #0C0C0C;
    --layer-light-two: #EBEBEB;
    --layer-light-three: #FAFAFA;
    --layer-white: #FFFFFF;
    --layer-gray: #878787;
    --layer-dark-one: #0C0C0C;
    --layer-dark-two: #181818;
    --layer-dark-three: #242424;
    
    --text-white: #FFFFFF;
    --text-light-darkgrey: #242424;
    --text-light-lightgrey: #6A6A6A;
    --text-light-blue: #0069C2;
    --text-dark-darkgrey: #8B8B8B;
    --text-dark-lightgrey: #F2F2F2;
    --text-dark-blue: #008CFF;

    --font-family: "Ericsson Hilda";
    --font-medium-label: 18px;
    --font-large-label: 22px;
}

// THIS IS HERE BECAUSE FOR SOME REASON <Column asContainer/> has 3px bottom margin
.column.container {
    margin-bottom: 0px !important;
}

.tile.fullscreen {
    height: 100% !important;
}

// When user is logged in, user setting menu slides content out of screen
.app.slide-left {
    margin-left: auto !important;
}