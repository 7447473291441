.row >.quality-status-kpi{
    flex-direction: column;
    margin-bottom: 16px;
}

.kpi-subtitle{
    font-weight: bold;
}

.trophic-index {
    margin: 5px 0;
    padding-right: 50px;
}

.trophic-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}