.locations-list-table-container {
    background-color: var(--layer-white);
    & tr {
        cursor: pointer;
    }
}

.list-page {
    & .view-toggle {
        padding-left: 12px !important;
    }

}

.cover-image {
    padding-top: 4px;
    padding-bottom: 4px;
}

.location-card .cover-image {
    cursor: pointer;
}

@media only screen and (max-width: 300px) {
    .locations-list-table-container {
        font-size: small;
    }
}