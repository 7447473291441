.locationPicture {
    max-width: 400px;
}

.location-details-tabs {
    padding: 0px !important;
    & .header {
        margin: 16px 8px 16px 8px !important;
        padding: 0px !important;
    }
    & .cover-info {
        padding: 0px 12px 12px 12px !important;
    }
    & .icon.title {
        padding: 0px 8px 0px 8px !important;
        cursor: pointer;
        &.active {
            border-bottom: 3px solid var(--text-light-blue) !important;
        }
    }
}

@media only screen and (max-width: 640px) {
    .location-details-tabs .content {
        min-height: 207px;
    }    
}

@media only screen and (max-width: 1280px) {
    .location-details-tabs .content {
        min-height: 307px;
    }    
}

@media only screen and (min-width: 1280px) {
    .location-details-tabs .content {
        min-height: 395px;
    }    
}


  
