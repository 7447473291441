.title-label {
    color: var(--text-light-lightgrey);
}

.location-name {
    font-size: var(--font-medium-label);
}

.report-block {
    padding-top: 16px;

    & .details-label {
        font-size: var(--font-medium-label);
    }

    & .values {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-top: 1px solid var(--text-dark-darkgrey);
        & .value-block {
            display: inline-block;
            width: 50%;
            padding: 8px;
            & .content {
                height: fit-content;
                overflow: hidden;
                padding: 0px;
            }
            & .resource-status {
                color: var(--text-light-lightgrey);
            }
        }
    }

    & img {
        width: 100%;
        height: 100%;
    }
}


@media screen and (max-width: 280px) {
    .location-name {
        font-size: 16px;
    }
    .value-block .value .text-xl {
        font-size: large;
    } 
}