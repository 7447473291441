.aditional-filter-items {
    & .filter-item {
        padding: 12px !important;
        width: 100%;
        display: inline-block;
    }
}
.export-table-tile .dropdown{
    margin-top: 10px;
}
.filter-item {
    & .current-options {
        left: 0 !important;
    }
    & .options-list {
        left: 0 !important;
    }
}
.location-select {
    & .current-options {
        width: 100% !important;
    }
    & .options-list {
        width: 100% !important;
    }
    & .options_container {
        width: 50vw !important;
    }
}

.aditional-items-toggle {
    position: inherit;
}

.filter-tile {
    padding: 16px !important;
    & div.header {
        padding-bottom: 0px !important;
    }
}

.pill.filter-type.selected {
    border-color: var(--status-dark-blue);
    color: var(--status-dark-blue);
    &.icon {
        color: var(--status-dark-blue);
    }
}

.range-button-column {
    align-items: end;
}

.filter-item .dropdown .menu {
    left: 0px;
}
@media screen and (max-width: 420px) {
    .appcontent .filter-tile .right {
        width: 50%;
        font-size: small;
    }
    .appcontent .filter-tile .left {
        width: 50%;
    }
    .light .pill {
        margin-right: 5px;
    }
}

@media screen and (max-width: 320px) {
    .appcontent .filter-tile .right {
        font-size: x-small;
    }
    .location-details-tabs .left {
        width: 100%;
    }
    .light .tile .header .right {
        font-size: smaller;
        margin-left: 10px;
    }
    .light input.with-icon {
        font-size: small;
        width: 120px;
    }
}

@media screen and (max-width: 280px) {
    .light .pagination-group ul.pagination li {
        margin: 0 1px;
    }
}
