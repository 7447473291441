.login-disabled {
    pointer-events: none;
    color: var(--text-light-lightgrey);
}

.appnav {
    overflow-x: auto !important;
}
ul > li:nth-child(3) > ul > li:nth-child(1) > div > span {
    vertical-align: bottom;
}
ul span {
    vertical-align: bottom;
}

#alarm-notifications {
    position: relative;
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    margin-top: 0%;
    min-height: fit-content !important;
    display: flex;
  }
  
  #alarm-notifications > div:nth-child(1){
    padding-right: 150px;
  }
  
  .item.defaultCursor {
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 10px;
    text-align: center;
    margin: auto;
    min-height: fit-content !important;
    max-width: fit-content !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    position: absolute;
    top: 10px;
    left: 25px;
  }
  
  .item.defaultCursor:hover{
    background-color: red !important;
  }
  
  @media only screen and (min-width: 30em) {
      .light .appbar .title.open-menu { 
        left: 260px;
      }
      .light main .app .appnav {
        width: 260px;
      }
  }
