.appview-map-page{
    & .appbar{
        display: none;
    }

}
.map-main-container {
    width: 100%;
    height: 100%;
}

// overwrite sidebar style for mapPage
.map-filters {
    top: 48px !important;
    & .content {
        height: calc(100% - 50px) // 98 = app-header(48px) + sidebar-title(39px)
    }
}