
.date-range-selector {
    flex-direction: 'row';
    // fix issue with calendar icon position when resing input control
    & input {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.start-date-column {
    margin-right: 8px;
}

.end-date-column {
    margin-right: 16px;
}

.range-button-column {
    padding-top: 16px; 
    display: inline-flex;
    width: 50%;
    & .btn {
        min-width: 30% !important;
    }
}
.start-date-column {
    padding-right: 8px;
}
@media only screen and (max-width: 420px) {
    .range-button-column {
        width: 100%;
    }
}
@media only screen and (max-width: 400px) {
    .end-date-column {
        margin-top: 8px;
    }
    .light .calendar {
        width: 240px;
    }
}