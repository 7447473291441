.row-tsi-content {
    width: 100%;
}

.accordion.accordion-tsi {
    width: 100%;
}
.no-thresholds {
    color: var(--text-light-lightgrey);
}
.accordion-list {
    & .content {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
    & .tile {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & .button-group-left {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}

@media screen and (max-width: 360px) {
    .accordion-tsi .accordion-list .accordion-item .content {
        padding: 0;
    }
    .accordion-tsi .accordion-list .accordion-item .title {
        font-size: smaller;
    }
}
