.title-date-range-selector {
    flex-direction: row;
}

.latest-measurements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & .stats-block {
        display: inline-block;
        width: 20%;
        min-width: 190px;
        & .text- {
            min-height: 34px;
        }
    }
}
