.invalid-rows{
    & .block {
        padding: 12px 12px 0px 12px;
        border-bottom: 0.5px solid var(--text-light-lightgrey);
        overflow-x: auto;
        white-space: nowrap;
    }
    & .title {
        color: var(--text-light-lightgrey);
        padding-bottom: 2px;
    }
    & .value {
        padding-bottom: 12px;
    }
    & .column {
        display: inline-block;
        width: 20%;
    }
}

.samplinglog-details {
    & .title {
        border-bottom: 0.5px solid var(--text-light-lightgrey);
    }
}