.carousel-slide {
    padding: 0px !important;
    & img {
        height: 100% !important;
        width: 100% !important;
    }
}

.picture-gallery.single {
    & button {
        display: none !important;
    }
}

.carousel-container {
    max-height: 300px !important;
    & .carousel-counter {
        color: var(--text-white) !important;
    }
    & button {
        color: var(--text-white) !important;
        border: 1px solid var(--text-white) !important;
    }
}