.cookies-policy-container {
    height: 100% !important;
}

.cookies-policy-we-use {
    padding-bottom: 8px;
}

@media only screen and (max-width: 420px) {
    .light .cookie-policy-title .appbar {
        line-height: 15px;
        height: 40px;
        margin: 10px 0;
    }   
    .tile.cookies-policy-container{
        padding: 0;
    }
    .cookies-policy-container .text-{
        margin: 8px 0;
        padding: 0 16px;
    }
    .cookies-policy-container .text-xl{
        margin: 8px 0;
        padding: 0 16px;
    }
    .cookies-policy-container .content .tile {
        padding: 0;
    }
    .cookie-content-tile {
        padding: 0;
    }
    .table.cookie-policy-table tr th {
        padding: 5px 2px;
        white-space: break-spaces;
    }
    .table.cookie-policy-table tr td {
        padding: 5px 2px;
        white-space: break-spaces;
        font-size: smaller;
    }
    .cookies-policy-container .content table {
        font-size: xx-small;
    }
}

@media only screen and (max-width: 280px){
    .cookies-policy-container .text-xl{
        margin: 5px 0;
        padding: 0 10px;
        font-size: larger;
    }
    .cookies-policy-container .content table {
        font-size: 6px;
    }
}