.measurements-points {
    & .measurements-table tr {
        cursor: pointer;
    }
    & .count-selected {
        display: none;
    }
}

.sample-points {
    & .samples-table tr {
        cursor: pointer;
    }
    & .count-selected {
        display: none;
    }
}

.export-table-tile .dropdown .menu {
    left: 8px;
}

.loading-data {
    display: inline-block !important;
    margin-right: 4px;
}

.btn::before {
    top: 45% !important;
}

.btn::after {
    top: 45% !important;
}

.pagination-group .actions {
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.pagination-group .options-list {
    width: 60px !important;
}
.light .tile .header .right .btn {
    margin: 0 8px 0px 8px;
}
@media screen and (max-width: 420px) {
    .measurements-points table {
        font-size: small;
    }
    .table.samples-table tr th, .table.samples-table tr td {
        padding: 8px 2px;
        font-size: x-small;
        white-space: break-spaces;
    }
    .table.measurements-table tr th, .table.measurements-table tr td {
        padding: 8px 2px;
        font-size: x-small;
        white-space: break-spaces;
    }
    .measurements-points .table-top {
        margin-bottom: 5px;
    }
    .light .tile .header{
      padding-bottom: 12px;  
    }
    .pagination-group .actions{
        display: flex;
        align-items: center;
        margin-right: 15px;
    }
    .pagination-group .options-list {
        width: 60px !important;
    }
    .light .tile .header .right .btn {
        margin: 0 8px 0px 8px;
        font-size: small;
    }
    .light .btn {
        font-size: small;
    }
}

@media screen and (max-width: 280px) {
    .measurements-points table {
        font-size: x-small;
    }
}