@media only screen and (max-width: 420px) {
    .sidebar {
        width: 100% !important;
    }
}

.sidebar {
    visibility: hidden;
    height: 100%;
    padding: 4px;
    background: var(--layer-light-three);
    border-left: 3px solid var(--layer-light-two);
    position: absolute;
    top: 96px; // app-header(48px) + appview-header(48px)
    right: 0;
    width: 330px;
    visibility: hidden;
    &.narrow {
        width: 250px;
    }
    &.wide {
        width: 420px;
    }
    z-index: 9999;
    &.open {
        visibility: visible;
    }

    & .close {
        cursor: pointer;
        position: inherit;
        top: 0;
        right: 0px;
        padding: 16px;
        color: var(--text-light-darkgrey);
    }
    & .title {
        padding: 8px;
        font-size: var(--font-medium-label);
        color: var(--text-light-darkgrey);
        border-bottom: 1px solid var(--layer-light-two);
    }
    & .content {
        overflow-x: hidden;
        padding-bottom: 24px;
        height: calc(100% - 157px); // 157 = app-header(48px) + appview-header(48px) + sidebar-title(61px)
    }

    & .resource-block {
        display: inline-block;
        width: 50%;
        padding: 8px !important;
    }
}