.map-control-menu {
    cursor: pointer;
    padding-top: 0px !important;
    & .item.inactive {
        padding: 4px;
        background-color: var(--layer-light-three);
        border-bottom: 1px dashed var(--layer-light-two);
        border-left: 1px dashed var(--layer-light-two);
        & .icon {
            color: var(--status-grey);
        }
        & span {
            color: var(--status-grey);
            padding-left: 4px;
        }                
    }

    & .item.active {
        padding: 4px;
        background-color: var(--layer-light-three);
        border-bottom: 1px solid var(--layer-light-two);
        border-left: 1px solid var(--layer-light-two);
        & span {
            color: var(--text-light-darkgrey);
            padding-left: 4px;
        }

        & .status-type-NONE {
            color: var(--status-grey);
        }
    
        & .status-type-UNKNOWN {
            color: var(--status-black);
        }

        & .status-type-HYPERTROPHIC {
            color: var(--status-dark-blue);
        }
        
        & .status-type-EUTROPHIC {
            color: var(--status-green);
        }
        
        & .status-type-MESOTROPHIC {
            color: var(--status-yellow);
        }
        
        & .status-type-OLIGOTROPHIC {
            color: var(--status-orange);
        }
        
        & .status-type-ULTRA_OLIGOTROPHIC {
            color: var(--status-red);
        }        
    } 

    & .clear-filters-pill {
        padding-top: 4px;
    }   
}

// !important flag set for styling leaflet controls to match EDS style

.leaflet-control {
    border: none !important;
    .leaflet-control-zoom-in {
        border: 0.5px solid var(--layer-dark-three);
        border-bottom: none !important;
    }
    .leaflet-control-zoom-out {
        border: 0.5px solid var(--layer-dark-three) !important;
    }
}

.leaflet-top.leaflet-right .leaflet-control {
    background-color: var(--layer-light-three);
    border: 0.5px solid var(--layer-dark-three) !important;
}

.btn.filter-button{
    min-width: 48px;
    min-height: 48px;
    border: none !important;
}